app.controller("payment",['$scope','$rootScope','Api',function ($scope,$rootScope,Api){
    $scope.users=[];

    var getUsers=function(){
      Api.getUsers().then(
        function(result){
            $scope.users=result.data.users;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getUsers();

    $scope.newPayment=function(post){
      console.log(post)
      Api.newPayment(post).then(
        function(){
            $rootScope.redirect("/payments")
        },
        function(error){
        }

      );
    }
  }]
)

  