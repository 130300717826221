app.controller("groups",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog){
    $scope.groups=[];
    $scope.filteredGroups=[];
    $scope.searchString=""
    $scope.currentNavItem="groups"
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredGroups = $scope.groups.filter(group => group.name.match(nameRegexp));
    }
    var getGroups= function (){
      Api.getGroups().then(
        function(result){
            $scope.groups=result.data.groups;
            $scope.filteredGroups=result.data.groups;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getGroups();
    $scope.showRemoveDialog=function(group){
      var confirm = $mdDialog.confirm()
        .title($translate.instant("CONFIRM_REMOVE_GROUP_TITLE"))
        .textContent($translate.instant("CONFIRM_REMOVE")+ group.name +"?")
        .ok($translate.instant("YES"))
        .cancel($translate.instant("NO"));

      $mdDialog.show(confirm).then(function () {
        removeGroup(group)
      }, function () {
      });
    };
  var removeGroup=function(group){
      Api.deleteGroup(group).then(
        function(result){
         getGroups();
          $scope.searchString=""
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };

    $scope.newGroup=function(data){
      Api.newGroup(data).then(
        function(result){
         getGroups();
        $scope.searchString=""
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
  
  }])