app.controller("settings",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog','SettingsService',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog,SettingsService){
      $scope.currentNavItem=5
      $scope.language=SettingsService.getLanguage();
      $scope.languages=SettingsService.getLanguages()
      $scope.setMatchError = function(input1, input2,registerForm) {
        $scope.registering=false;
        if(input1.$viewValue!="" && input1.$viewValue!=null){
          if (input1.$viewValue!=input2.$viewValue) {

                input1.$setValidity('match',false);
          } else {
                delete input1.$error['match'];
          }
        }
      }
      var showAlert= function () {
        $mdDialog.show(
          $mdDialog.alert()
            .clickOutsideToClose(true)
            .title($translate.instant("SUCCESS_TITLE"))
            .textContent($translate.instant("SUCCESS_CHANGE_PASSWORD"))
            .ok('OK')
        );
      };
      $scope.changePassword= function  (body){
        Api.changePassword(body).then(
          function(result){
              Api.refreshCredentials(result.data);
               showAlert()
              },
              function(result){
              }

            );

        };
      $scope.changeLanguage=function(){
        SettingsService.setLanguage($scope.language);
      }
    }])
 