(function () {
    'use strict';
    app.factory("SettingsService", function ($window, $translate) {
        var factory = {};
        var languages = ["en", "es"];
        factory.init = function init () {
            if ($window.localStorage.settings == null) {
                var settings = {
                    notifications: false,
                    reminders: false, 
                    language: "en"
                };
                $window.localStorage.setItem("settings", JSON.stringify(settings));
            }
        }
    
        factory.getSettings = function () {
            if ($window.localStorage.settings != null) {
                return JSON.parse($window.localStorage.settings);
            } else {
                return null
            }
        }
    
        factory.setSetting = function (key, value) {
            if ($window.localStorage.settings != null) {
                var settings = JSON.parse($window.localStorage.settings);
                settings[key] = value;
                $window.localStorage.setItem("settings", JSON.stringify(settings));
                return true
            } else {
                return false
            }
        }
        factory.resetSettings=function(){
            $window.localStorage.clear();
            factory.init();
        }
        factory.getSetting = function (key) {
            if ($window.localStorage.settings != null) {
                var settings = JSON.parse($window.localStorage.settings);
                return settings[key]
            } else {
                return null
            }
        }
        factory.getLanguages = function () {
            return languages;
        }
    
        factory.getLanguage = function () {
                if ($window.localStorage.settings != null) {
                    var settings = JSON.parse($window.localStorage.settings);
                
                    if (settings.language) {
                        return settings.language
                    }
                }
                if (languages.includes(window.navigator.language.slice(0, 2))) {
                    return window.navigator.language.slice(0, 2)
                } else {
                    return languages[0];
                }
                
        }
    
        factory.setLanguage = function (lang) {
            if (languages.includes(lang)) {
                $translate.use(lang)
                factory.setSetting("language", lang);
            }
        }
    
    
        return factory;
    })

})();
