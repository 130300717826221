var app = angular.module('Dashboard', ['ngMaterial','ngRoute','pascalprecht.translate','ngSanitize','ngMessages','mdCollectionPagination','chart.js']);
    app.config(function ($routeProvider,$locationProvider) {
        $locationProvider.html5Mode(true);

        $routeProvider 
        .when("/", {templateUrl: 'main.html',controller:'mainCtrl'})
        .when("/resetpassword/:token", {templateUrl: 'reset.html',controller:'reset'})
        .when("/users", {templateUrl: 'users.html',controller:'users'})
        .when("/register", {templateUrl: 'register.html',controller:'registerCtrl'})
        .when("/groups", {templateUrl: 'groups.html',controller:'groups'})
        .when("/settings", {templateUrl: 'settings.html',controller:'settings'})
        .when("/keys", {templateUrl: 'keys.html',controller:'keys'})
        .when("/beers", {templateUrl: 'beers.html',controller:'beers'})
        .when("/beer", {templateUrl: 'beer.html',controller:'beer'})
        .when("/foods", {templateUrl: 'foods.html',controller:'foods'})
        .when("/food", {templateUrl: 'food.html',controller:'food'})
        .when("/beer/:id", {templateUrl: 'beer.html',controller:'beer'})
        .when("/historic", {templateUrl: 'historic.html',controller:'historic'})
        .when("/payments", {templateUrl: 'payments.html',controller:'payments'})
        .when("/payment", {templateUrl: 'payment.html',controller:'payment'})
        .when("/debts", {templateUrl: 'debts.html',controller:'debts'})
        .when("/hall", {templateUrl: 'hall.html',controller:'hall'})
        .when("/forgotpassword", {templateUrl: 'forgot.html',controller:'forgotCtrl'})
        .otherwise({ redirectTo: '/' });
  })

    .config(function($sceDelegateProvider){ // Allow loading resources from custom urls
        $sceDelegateProvider.resourceUrlWhitelist([
            'self',// Allow same origin resource loads.
            // Allow loading from our assets domain. **.
            //Add here rebuild api address

          ]);   
    })
    .config(['$translateProvider', function ($translateProvider) {
        // add translation tables
        $translateProvider.useStaticFilesLoader({
            prefix: 'locale/',
            suffix: '.json'
        }).fallbackLanguage('en');
        $translateProvider.useSanitizeValueStrategy('sanitizeParameters');
    }])
    .run(function($rootScope, $translate, $location, $http, SettingsService,$route,Api) {
        // Pages to be accessible for everyone
         var unrestrictedPages = ['/login', '/register']
        // keep user logged in after page refresh
 
        
        $translate.use(SettingsService.getLanguage());

        Api.isFirstRun().then(
            function(){
                $rootScope.firstRun=false;
            },
            function(data){
                if(data.status=404){
                    $rootScope.firstRun=true;

                }else{
                    $rootScope.firstRun=false;
                }
            })
        $rootScope.isAdmin=function(){return Api.isAdmin()}
        $rootScope.isLogged=function(){ return Api.isLogged();}
        $rootScope.logout=function (){  Api.logout();$rootScope.username=""; $location.url("/")}
        $rootScope.redirect= function(link){$location.url(link)};
        $rootScope.username="";
    });

