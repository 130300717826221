app.controller('forgotCtrl',function($scope,Api){
    $scope.disabled=false;
    if(Api.isLogged()){
      $location.path( "/changepassword" );
    }else{
    $
    $(".custom-error").css("opacity",0)
     $scope.sendEmail= function  (body){

      $scope.sent=false;

      $(".custom-error").css("opacity",0)
        Api.forgotPassword(body).then(
          function(result){
                $scope.enabledButton="true";
                $scope.sent=true;
          },
          function(result){
                $scope.sent=true;

         
          }

        );

    };

    }
  })