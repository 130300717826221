  app.controller("keys",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog' ,function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog){
    $scope.keys=[];
    $scope.filteredKeys=[];
    $scope.searchString=""
    $scope.currentNavItem="keys"
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredKeys = $scope.keys.filter(key => key.name.match(nameRegexp) || key.user.user.match(nameRegexp));
    }
    var getKeys= function (){
      Api.getKeys().then(
        function(result){
            $scope.keys=result.data.keys;
            $scope.filteredKeys=result.data.keys;
        },
        function(error){
          $scope.keys=[];
           $scope.filteredKeys=[]
        }

      );
    }
     $scope.showRemoveDialog=function(key){
      var confirm = $mdDialog.confirm()
          .title($translate.instant("CONFIRM_REMOVE_KEY_TITLE"))
          .textContent($translate.instant("CONFIRM_REMOVE")+ key.name +"?")
          .ok($translate.instant("YES"))
          .cancel($translate.instant("NO"));

        $mdDialog.show(confirm).then(function () {
          removeKey(key)
        }, function () {
        });
      };
    var removeKey=function(key){
      Api.deleteKey(key).then(
        function(result){
          getKeys();
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
    
    $scope.newKey=function(data){
      Api.newKey(data).then(
        function(result){
                    getKeys();

        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
    $scope.toggleEnabled=function(key){
      Api.toggleEnabledKey(key).then(
        function(result){
          key.enabled=result.data.key.enabled;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };

    $scope.isLogged=function (){ if(Api.isLogged()){return true;} else{  $location.path( "/" );return false;}};
    $scope.logout=function(){ return Api.logout(); $location.path( "/" );}
    $scope.isAdmin=function(){return Api.isAdmin()}

    getKeys();

  }]);