app.controller("reset",['$scope','$http','$location','$routeParams','Api',function($scope,$http,$location,$routeParams,Api){
    $scope.disabled=false;
    $scope.error=false;
    $scope.success=false;
    if(Api.isLogged()){
      $location.path("/changepassword" );
    }else{
     $scope.token = $routeParams.token;
     $scope.setMatchError = function(input1, input2,registerForm) {
        $scope.registering=false;
        if(input1.$viewValue!="" && input1.$viewValue!=null){
          if (input1.$viewValue!=input2.$viewValue) {

                input1.$setValidity('match',false);
                $scope.valid=false;

          } else {
                delete input1.$error['match'];
                $scope.valid=true;
          }
        }
      }
     $scope.resetPassword= function(body){
      $scope.disabled=true;
        $("#error_msg").hide();
        Api.resetPassword($scope.token,body).then(
          function(result){
            $scope.success=true;
          },
          function(result){
              $scope.error=true;
          }
        );
      };
    }
  }])