app.controller("payments",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog){

    $scope.payments=[];

    $scope.currentNavItem=4

    var getPayments= function (){
      Api.getPayments().then(
        function(result){
            $scope.payments=result.data.payments;
          
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getPayments();




  }])