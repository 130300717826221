app.controller("historic",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog','ENV_VARS',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog,ENV_VARS){

    $scope.imagePath= ENV_VARS.imagePath;
    $scope.eats=[];
    $scope.drinks=[];
    $scope.historic=[]
    $scope.filteredBeers=[];

    $scope.searchString=""
    $scope.currentNavItem=3 
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredBeers = $scope.beers.filter(beer => (beer.beer?beer.beer.name.match(nameRegexp):beer.food.name.match(nameRegexp))|| beer.user.user.match(nameRegexp));
    }
    var getBeers= function (){
      Api.getDrinks().then(
        function(result){
            $scope.drinks=result.data.drinks;
             Api.getEats().then(
                function(result){
                  $scope.eats=result.data.eats;
                  console.log($scope.eats)
                  const set = new Set();
                  const merged = $scope.drinks.concat($scope.eats).sort((a, b) =>  new Date(b.timestamp) - new Date(a.timestamp));
                  $scope.beers=merged
                  $scope.filteredBeers=merged;
                },
                 function(error){
                    $scope.errors=error.message;
                }

              )
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getBeers();




  }])