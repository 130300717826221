app.controller("beer",['$scope','$routeParams',"$rootScope",'Api','ENV_VARS',function($scope,$routeParams,$rootScope,Api,ENV_VARS){
    $scope.imagePath= ENV_VARS.imagePath;
    $scope.id=$routeParams.id || false
    $scope.beers=[];
    $scope.filteredBeers=[];
    $scope.searchString=""
    $scope.post={}
    $scope.beer
    let saving=false;
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredBeers = $scope.beers.filter(beer => beer.name.match(nameRegexp));
    }
    if($scope.id){
      Api.getBeer($scope.id).then(
        function(result){
            $scope.post=result.data.beer;
            $scope.beer=result.data.beer;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }

    $scope.saveBeer=function (post){
      if(!saving){
        let saving=true;

        var formData = new FormData();
        if(!$scope.id || ($scope.id && $scope.file)){
          formData.append("image", $scope.file);
        }
        formData.append('type',post.type);
        formData.append('price', post.price);
        formData.append('stock', post.stock); 
        formData.append('name', post.name); 
        if($scope.id){
          Api.updateBeer($scope.id,formData).then(
            function(result){
               $rootScope.redirect("beers")
               $scope.post={}
               let saving=false;
            },
            function(error){
              $scope.errors=error.message;
              let saving=false;

            }
          );
        }else{
           Api.saveBeer(formData).then(
            function(result){
               $rootScope.redirect("beers")
               $scope.post={}
               let saving=false;
            },
            function(error){
              $scope.errors=error.message;
              let saving=false;

            }

          );
        }
      }
    }

  

  }])