app.controller("mainCtrl",['$scope','$http','Api','$mdSidenav','$timeout','$rootScope',function($scope,$http,Api,$mdSidenav,$timeout,$rootScope){

      $scope.rules=0;
      $scope.sensors=0;
      $scope.alarms=0;
      $scope.post={};
      $scope.resume={};
      $scope.login=login;
      $scope.type_labels = [];
      $scope.type_data = [];
      $scope.brands_labels=[]
      $scope.brands_data=[]
      $scope.employee="alberdibu_oct_dic.jpg"
      $scope.num_beers=22
      $scope.month="OCT-DIC"
      $scope.employee_labels = [];
      $scope.employee_data = [];
      $scope.setMatchError = function(input1, input2,registerForm) {
        $scope.registering=false;
        if(input1.$viewValue!="" && input1.$viewValue!=null){
          if (input1.$viewValue!=input2.$viewValue) {
            if(registerForm){
                registerForm.email.$setValidity('duplicated',true);
            }
                 input1.$setValidity('match',false);
            } else {
                 delete input1.$error['match'];
            }
        }
      }
      $scope.signUp= function  (body){
       $(".custom-error").hide()
        Api.newUser(body).then(
          function(result){
            $rootScope.firstRun=false
            ;
          },
          function(result){
            $('.password').addClass('wrong-log');
            $timeout( function(){
              $('.password').removeClass('wrong-log');
            },400 );
          }
        );
      };
      if($scope.isLogged()){
         getResume();
          
      }
      function getResume(){

         Api.getUserResume().then(
          function(result){
              
            $scope.type_labels = [];
            $scope.type_data = [];
            for (var type in result.data.resume.types) {
              $scope.type_labels.push(type);
              $scope.type_data.push(result.data.resume.types[type]);
            }
            for (var brand in result.data.resume.brands) {
              $scope.brands_labels.push(brand);
              $scope.brands_data.push(result.data.resume.brands[brand]);
            }
            $scope.resume=result.data.resume
            ;

          },
          function(result){
           
          }
        );
        Api.getEmployeesOfTheMonth().then(
          function(result){
              
            $scope.employee_labels = [];
            $scope.employee_data = [];
            for (let i=0; i<result.data.employees.length;i++) {
              $scope.employee_labels.push(result.data.employees[i]._id);
              $scope.employee_data.push(result.data.employees[i].count);
            }
          },
          function(result){
           
          }
        );

      }
      function login (body,form){
          $("#error_msg").hide();
          Api.login(body).then(
            function(result){
              Api.setCredentials(result.data,body.user);
              getResume()
              $scope.post={};
              form.$valid
              form.$setPristine();
              form.$setUntouched();

            },
            function(result){
              $('#password_container').addClass('wrong-log');
              $('#user_container').addClass('wrong-log');

              $timeout( function(){
                  $('#password_container').removeClass('wrong-log');
                 $('#user_container').removeClass('wrong-log');
              },400 );
            }

          );

      }


 

  }])