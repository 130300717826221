app.controller("debts",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog){

    $scope.debts=[];
    $scope.searchString=""
    $scope.filteredDebts=[];
    $scope.currentNavItem=7

     $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredDebts = $scope.debts.filter(debt => debt.user.match(nameRegexp));
    }
    var getDetbts= function (){
      Api.getDebts().then(
        function(result){
            $scope.debts=result.data.debts;
          $scope.filteredDebts =result.data.debts;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getDetbts();
  }])