app.controller("foods",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog','ENV_VARS',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog,ENV_VARS){
    $scope.imagePath= ENV_VARS.imagePath;
    $scope.foods=[];
    $scope.filteredFoods=[];
    $scope.searchString=""
    $scope.timestamp=(new Date()).getTime()
    $scope.currentNavItem=2;
      let saving=false;
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredFoods = $scope.foods.filter(food => food.name.match(nameRegexp));
    }
    var getFoods= function (){
      Api.getFoods().then(
        function(result){
            $scope.foods=result.data.foods;
            $scope.filteredFoods=result.data.foods;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getFoods();

    function DialogController($scope, dataToPass) {
      $scope.amount=1
      console.log(dataToPass)
      $scope.food=dataToPass;
      $scope.hide = function() {
        $mdDialog.hide();
      };

      $scope.cancel = function() {
        $mdDialog.cancel();
      };

      $scope.answer = function(answer) {
        $mdDialog.hide(answer);
      }
    } 

   $scope.confirmEatDialog = function(food) 
    {
        $mdDialog.show({
          locals:{dataToPass: food},                
          controller: DialogController,
          templateUrl: 'partials/save_eat.html', //custom template to load on the dialog
          parent: angular.element(document.body),
          scope: $scope,
          preserveScope: true,  
          clickOutsideToClose:true,
          fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(function(amount) {
          saveEat(food,amount);
        }, function() {
          //close the dialog
        });
    };


    var saveEat=function(food,amount){
      Api.saveEat(food._id,amount).then(
        function(result){
         saving=false;
         getFoods();
        },
        function(error){
          saving=false;
          $scope.errors=error.message;
        }

      );
    };

  }])