
app.factory("Api",['$http','ENV_VARS', function($http,ENV_VARS){
    const PATH = ENV_VARS.apiUrl
    var factory = {};

    var apiKey =  null;

    factory.login = function(data) {
            url=PATH+"login";
            return $http.post(url,data);
    }

    factory.logout= function(){
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem("expires_at");
      localStorage.removeItem('user')
    };

    factory.isLogged= function(){
            var now=Date.now()/1000;
            var expiration = localStorage.getItem("expires_at");
            return now<expiration;
        };
    factory.setCredentials=function(data,user){
      localStorage.setItem('token', data.token);
      localStorage.setItem('role', data.role);
      localStorage.setItem("expires_at", data.expires );
      localStorage.setItem('user',user)

    }
    factory.refreshCredentials=function(data){
      localStorage.setItem('token', data.token);
      localStorage.setItem('role', data.role);
      localStorage.setItem("expires_at", data.expires );
    }
    factory.isFirstRun = function() {
        url=PATH+"firstrun";
        return $http.get(url);

    }
    factory.getUsers = function() {
        url=PATH+"users";
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.getEmails = function() {
        url=PATH+"emails";
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.removeUser = function(user) {
        url=PATH+"user/"+user._id;
        return $http.delete(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.toggleAdmin = function(user) {
        url=PATH+"users/toggle/admin/"+user._id;
        return $http.put(url,{}, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.toggleEnabled = function(user) {
        url=PATH+"users/toggle/enabled/"+user._id;
        return $http.put(url,{}, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    },
    factory.isAdmin=function(){
      return localStorage.getItem('role')=="admin";
    },
    factory.newUser = function(data) {
        url=PATH+"user";
        return $http.post(url,data);

    },
    factory.changeGroupUser= function(data){
        console.log(data);
        url=PATH+"user/changegroup";
        return $http.post(url,data,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    },
    factory.getGroups = function() {
        url=PATH+"groups";
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    } 
    factory.getBeers = function() {
        url=PATH+"beers";
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.getBeer = function(id) {
        url=PATH+"beer/"+id;
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.getFoods = function() {
        url=PATH+"foods";
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.getFood = function(id) {
        url=PATH+"food/"+id;
        return $http.get(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }      
    factory.saveDrink=function(beer,amount){
        url=PATH+"drink";
        return $http.post(url,{beer:beer,amount:amount},{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.saveEat=function(food,amount){
        url=PATH+"eat";
        return $http.post(url,{food:food,amount:amount},{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }       
    factory.newGroup = function(data) {
        url=PATH+"group";
        return $http.post(url,data,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    },
    factory.deleteGroup = function(group) {
        url=PATH+"group/"+group._id;
        return $http.delete(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    },
    factory.newKey = function(data) {
        url=PATH+"key";
        return $http.post(url,data,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    },
    factory.getKeys = function() {
        url=PATH+"keys";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    },
    factory.deleteKey = function(key) {
        url=PATH+"keys/"+key._id;
        return $http.delete(url, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    },
    factory.toggleEnabledKey = function(key) {
        url=PATH+"keys/"+key._id;
        return $http.put(url,{}, {headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});

    }
    factory.changePassword = function(data) {
        url=PATH+"user/changepassword";
        return $http.post(url,data,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    },
    factory.forgotPassword = function(data) {
        url=PATH+"user/forgotpassword";
        return $http.post(url,data);
    },
    factory.resetPassword = function(token,data) {
        url=PATH+"users/resetPassword/"+token;
        return $http.post(url,data);
    },
    factory.getTemplates = function() {
        url=PATH+"templates";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    },
    factory.getDrinks=function(){
        url=PATH+"drinks";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    }
    factory.getEats=function(){
        url=PATH+"eats";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    }
    factory.getUserResume=function(){
        url=PATH+"user/resume";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    }

    factory.getEmployeesOfTheMonth=function(){
        url=PATH+"users/month";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    }
     factory.getDebts=function(){
        url=PATH+"users/resume";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    }
    factory.getPayments= function(){
        url=PATH+"payments";
        return $http.get(url,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}}); 
    }
    factory.newPayment= function(data) {
        url=PATH+"payment";
        return $http.post(url,data,{headers: {'Authorization':'Bearer '+localStorage.getItem('token')}});
    },
    factory.saveBeer=function(data){
         url=PATH+"beer";
        return $http.post(url,data,{transformRequest: angular.identity,headers: {'Authorization':'Bearer '+localStorage.getItem('token'),'Accept':'application/json','Content-Type': undefined}});
    }
    factory.updateBeer=function(id,data){
         url=PATH+"beer/"+id;
        return $http.put(url,data,{transformRequest: angular.identity,headers: {'Authorization':'Bearer '+localStorage.getItem('token'),'Accept':'application/json','Content-Type': undefined}});
    }
    factory.saveFood=function(data){
         url=PATH+"food";
        return $http.post(url,data,{transformRequest: angular.identity,headers: {'Authorization':'Bearer '+localStorage.getItem('token'),'Accept':'application/json','Content-Type': undefined}});
    }
    factory.updateFood=function(id,data){
         url=PATH+"food/"+id;
        return $http.put(url,data,{transformRequest: angular.identity,headers: {'Authorization':'Bearer '+localStorage.getItem('token'),'Accept':'application/json','Content-Type': undefined}});
    }
    factory.getPATH=function(){
      return PATH;
    }


    return factory;
}])
