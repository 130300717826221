app.controller("registerCtrl",['$rootScope','$scope','Api','$timeout',function($rootScope,$scope,Api,$timeout){
    $scope.post={}
    $scope.setMatchError = function(input1, input2,registerForm) {
        $scope.registering=false;
        if(input1.$viewValue!="" && input1.$viewValue!=null){
            if (input1.$viewValue!=input2.$viewValue) {

                if(registerForm){
                    registerForm.email.$setValidity('duplicated',true);
                }
                     input1.$setValidity('match',false);

                } else {

                     delete input1.$error['match'];
                }
            }
        }
     $scope.signUp= function  (body){
       $(".custom-error").hide()
        Api.newUser(body).then(
          function(result){
            if(Api.isAdmin()){
                $scope.post={}
                $rootScope.redirect( "/users" )
            }else{
               $rootScope.redirect( "/")
            }

          },
          function(result){
                $('md-input-controller').addClass('wrong-log');

              $timeout( function(){
                  $('md-input-controller').removeClass('wrong-log');
              },400 );
            $(".custom-error").html( result.data.message);
            $(".custom-error").show()
          }

          );

      };
    }])
