app.controller("users",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog',function ($scope,$http,$location,$routeParams,Api,$translate,$mdDialog){
    $scope.users=[];
    $scope.groups=[]
    $scope.searchString=""
    $scope.filteredUsers=[];
    $scope.currentNavItem=6;

     $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredUsers = $scope.users.filter(user => user.user.match(nameRegexp));
    }
    var getUsers=function(){
      Api.getUsers().then(
        function(result){
            $scope.users=result.data.users;
            $scope.filteredUsers=result.data.users;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getUsers();
    Api.getGroups().then(
      function(result){
          $scope.groups=result.data.groups;
      },
      function(error){
        $scope.errors=error.message;
      }
    );
    $scope.showRemoveDialog=function(user){
      var confirm = $mdDialog.confirm()
        .title($translate.instant("CONFIRM_REMOVE_TITLE"))
        .textContent($translate.instant("CONFIRM_REMOVE")+ user.user +"?")
        .ok($translate.instant("YES"))
        .cancel($translate.instant("NO"));

      $mdDialog.show(confirm).then(function () {
        removeUser(user)
      }, function () {
      });
    };
  var removeUser=function(user){
      Api.removeUser(user).then(
        function(result){
         getUsers();

        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
    $scope.toggleAdmin=function(user){
      Api.toggleAdmin(user).then(
        function(result){
          user.role=result.data.user.role;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
    $scope.toggleEnabled=function(user){
      Api.toggleEnabled(user).then(
        function(result){
          user.enabled=result.data.user.enabled;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    };
    $scope.changeGroupUser=function(user){
      Api.changeGroupUser({"user":user._id,"group":user.group?user.group:""}).then(
        function(result){
          console.log("Group changed");
        },
        function(error){

          $scope.errors=error.message;
        }

      );
    };
  }])