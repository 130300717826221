app.controller("food",['$scope','$routeParams',"$rootScope",'Api','ENV_VARS',function($scope,$routeParams,$rootScope,Api,ENV_VARS){
    $scope.imagePath= ENV_VARS.imagePath;
    $scope.id=$routeParams.id || false
    $scope.foods=[];
    $scope.filteredFoods=[];
    $scope.searchString=""
    $scope.post={}
    $scope.food
    let saving=false;
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredFoods = $scope.foods.filter(food => food.name.match(nameRegexp));
    }
    if($scope.id){
      Api.getFood($scope.id).then(
        function(result){
            $scope.post=result.data.food;
            $scope.food=result.data.food;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }

    $scope.saveFood=function (post){
      if(!saving){
        let saving=true;

        var formData = new FormData();
        if(!$scope.id || ($scope.id && $scope.file)){
          formData.append("image", $scope.file);
        }
        formData.append('type',post.type);
        formData.append('price', post.price);
        formData.append('name', post.name); 
        if($scope.id){
          Api.updateFood($scope.id,formData).then(
            function(result){
               $rootScope.redirect("foods")
               $scope.post={}
               let saving=false;
            },
            function(error){
              $scope.errors=error.message;
              let saving=false;

            }
          );
        }else{
           Api.saveFood(formData).then(
            function(result){
               $rootScope.redirect("foods")
               $scope.post={}
               let saving=false;
            },
            function(error){
              $scope.errors=error.message;
              let saving=false;

            }

          );
        }
      }
    }

  

  }])