app.controller("beers",['$scope','$http','$location','$routeParams','Api','$translate','$mdDialog','ENV_VARS',function($scope,$http,$location,$routeParams,Api,$translate,$mdDialog,ENV_VARS){
    $scope.imagePath= ENV_VARS.imagePath;
    $scope.beers=[];
    $scope.filteredBeers=[];
    $scope.searchString=""
    $scope.timestamp=(new Date()).getTime()
    $scope.currentNavItem=2;
      let saving=false;
    $scope.search=function() {
      let nameRegexp = new RegExp($scope.searchString, 'i');
      $scope.filteredBeers = $scope.beers.filter(beer => beer.name.match(nameRegexp));
    }
    var getBeers= function (){
      Api.getBeers().then(
        function(result){
            $scope.beers=result.data.beers;
            $scope.filteredBeers=result.data.beers;
        },
        function(error){
          $scope.errors=error.message;
        }

      );
    }
    getBeers();

    function DialogController($scope, dataToPass) {
      $scope.amount=1
      console.log(dataToPass)
      $scope.beer=dataToPass;
      $scope.hide = function() {
        $mdDialog.hide();
      };

      $scope.cancel = function() {
        $mdDialog.cancel();
      };

      $scope.answer = function(answer) {
        $mdDialog.hide(answer);
      }
    } 

   $scope.confirmDrinkDialog = function(beer) 
    {
        $mdDialog.show({
          locals:{dataToPass: beer},                
          controller: DialogController,
          templateUrl: 'partials/save_drink.html', //custom template to load on the dialog
          parent: angular.element(document.body),
          scope: $scope,
          preserveScope: true,  
          clickOutsideToClose:true,
          fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
        .then(function(amount) {
          saveDrink(beer,amount);
        }, function() {
          //close the dialog
        });
    };


    var saveDrink=function(beer,amount){
      Api.saveDrink(beer._id,amount).then(
        function(result){
         saving=false;
         getBeers();
        },
        function(error){
          saving=false;
          $scope.errors=error.message;
        }

      );
    };

  }])